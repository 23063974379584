
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Status, Line } from "./summary-interface";
import VueApexCharts from 'vue-apexcharts'

interface Series {
  name: string,
  data: number[],
}

interface Options {
  xaxis: { categories: string[] }
}

@Component({
  components: {
    VueApexCharts,
  }
})
export default class SummaryPieChart extends Vue {
  @Prop() pending!: boolean;
  @Prop() status!: Status[];
  @Prop() line!: Line[];
  @Prop() term!: string;

  get series(): Series[] {
    const series: Series[] = [];
    this.status.forEach(name => {
      const data: number[] = [];
      this.line.forEach(l => {
        data.push(l.status[name]);
      })
      series.push({ data, name });
    })
    return series;
  }

  get options(): Options {
    return {
      xaxis: {
        categories: this.line.map(r => r.date),
      }
    }
  }
}
