
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Projects, Status } from './summary-interface';

@Component
export default class SummaryTable extends Vue {
  @Prop() pending!: boolean;
  @Prop() status!: Status[];
  @Prop() projects!: Projects[];
}
