
import { Vue, Component } from 'vue-property-decorator';
import { Status, Pie, Line, Projects } from './summary-interface';
import SummaryPieChart from './pie-chart.vue';
import SummaryLineChart from './line-chart.vue';
import SummaryTable from './table.vue';
import moment from "moment";
import { USER_LEVEL } from "@/enum/enums";

@Component({
  components: {
    SummaryPieChart,
    SummaryLineChart,
    SummaryTable,
  }
})
export default class ProjectSummary extends Vue {
  private buyer: string | null = null;
  private term = 'day';
  private show = {
    calendar: false,
    pie: true,
    line: true,
    table: true,
  };
  private groupList: { _id: string, userGroup: string }[] = [];
  private status: Status[] = [];
  private pie: Pie[] = [];
  private line: Line[] = [];
  private projects: Projects[] = [];
  private date = [];

  async created() {
    if (!this.checkStartDate()) {
      this.projectStartDate = moment().startOf('month').format('YYYY-MM-DD');
    }

    if (!this.checkEndDate()) {
      this.projectEndDate = moment().format('YYYY-MM-DD');
    }

    await this.groups();
    await this.load();
  }

  checkStartDate() {
    new Date()
    return this.projectStartDate
        && this.projectEndDate
        && moment(this.projectStartDate).isSameOrAfter(moment(this.projectEndDate).add(-1, 'M'))
  }

  checkEndDate() {
    return this.projectEndDate
        && this.projectStartDate
        && moment(this.projectEndDate).isSameOrBefore(moment(this.projectStartDate).add(1, 'M'))
  }

  async groups(): Promise<void> {
    this.loading = true;
    const { data, result } = await this.axios({
      url: '/group'
    });
    if (result) {
      const { group } = data as { group: { _id: string, userGroup: string }[] };
      this.groupList = group;
    }
    this.loading = false;
  }

  async load() {
    this.loading = true;
    try {
      const { result, data, message } = await this.axios.get(`/project/summary`, {
        params: {
          startDate: this.projectStartDate,
          endDate: this.projectEndDate,
          term: this.term,
          buyer: this.buyer,
        }
      });
      if (result) {
        const { date, pie, line, projects, status } = data;
        this.date = date;
        this.pie = pie;
        this.line = line;
        this.projects = projects;
        this.status = status;
      }
    } catch (e) {
      console.log(e);
    }
    this.loading = false;
  }

  async csvDown() {
    if (!this.projects.length) return
    this.loading = true;
    try {
      const { result, data, message } = await this.axios.get('/project/summary/csv', {
        params: {
          startDate: this.projectStartDate,
          endDate: this.projectEndDate,
          term: this.term,
        },
        responseType: 'blob',
      })

      if (!result) throw message;

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'summary.csv');
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      this.validationMessages(e);
    }
    this.loading = false;
  }

  get isAdmin() {
    const { GENERAL_ADMIN, SUPER_ADMIN } = USER_LEVEL
    return [GENERAL_ADMIN, SUPER_ADMIN].includes(this.getUserLevel)
  }

  get groupOptions() {
    return [{ text: 'ALL', value: null }, ...this.groupList.map(({ _id, userGroup }) => {
      return {
        text: userGroup, value: _id
      }
    })];
  }
  get dateRange() {
    let endDate = moment(this.projectStartDate).add(1, 'M').format('YYYY-MM-DD')
    endDate = endDate > this.projectEndDate ? endDate : this.projectEndDate;
    return {
      start: {
        min: moment(this.projectEndDate).add(-1, 'M').format('YYYY-MM-DD'),
      },
      end: {
        max: endDate,
      },
    };
  }
}
