
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Status, Pie } from "./summary-interface";
import VueApexCharts from 'vue-apexcharts'

interface Options {
  legend: { position: string }, labels: Status[]
}
@Component({
  components: {
    VueApexCharts,
  }
})
export default class SummaryPieChart extends Vue {
  @Prop() pending!: boolean;
  @Prop() pie!: Pie[];
  @Prop() status!: Status[];

  get series(): number[] {
    return this.pie.map(r => r.count);
  }

  get options(): Options {
    return {
      labels: this.pie.map(r => r.status),
      legend: {
        position: 'bottom'
      }
    }
  }
}
